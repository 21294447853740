import React from 'react';
import { Form, Input, Button, Spin, Checkbox, Modal } from 'antd';
import { API_login } from './../api/auth';
import { notification } from 'antd';
import { connect } from 'react-redux';
import { setUser } from '../redux/actions';
import { useState } from 'react';
import { UserOutlined, LockOutlined} from '@ant-design/icons';


const LoginForm = props => {

    const [loading, setLoading] = useState(false);
    const [buttonDis, setbuttonDis] = useState(true);
    const [showModal, setshowModal] = useState(false); 

    const onFinish = values => {
        setLoading(true);
        console.log('Received values of form: ', values.username);
        API_login(values.username, values.password)
                    .then(response => {
                        props.setUser(values.username);
                        localStorage.setItem('accessToken', response.accessToken);
                        localStorage.setItem('login', values.username);
                        notification.success({ message: `Добро пожаловать ${values.username}!` });
                        props.history.push('/');
                    })
                    .catch(() => {
                        notification.error({ message: 'Пользователь с таким логином или паролем не найден' });
                    })
                    .finally(() => setLoading(false));
    };

    const onCheck = () => { 
        setbuttonDis(!buttonDis)
   }

   const resetModal = () => {
    setshowModal(!showModal)
}

    return (

        
        <Spin spinning={loading}>

            <Modal
            title="Политика в отношении обработки персональных данных"
            visible={showModal}
            onOk={resetModal}
            onCancel={resetModal}
            cancelButtonProps={{ style: { display: 'none' } }}
            centered
            width="100%"
            >
            <div class="resp-container">
                <iframe class="resp-iframe" src="https://www.s-vfu.ru/upload/politika_pdn.pdf" gesture="media"  allow="encrypted-media" allowfullscreen></iframe>
            </div>
            </Modal>

        <Form onFinish={onFinish} style={{ width: 300, margin: '0 auto' }}>
            <p style={{ fontWeight: 300, textAlign: 'center', fontSize: 20, marginBottom: 10 }}>Авторизация</p>
                <Form.Item style={{ marginBottom: 7 }} name="username" rules={[{ required: true, message: 'Введите логин' }]}>
                    <Input autoFocus prefix={<UserOutlined style={{ color: 'rgba(0,0,0,.25)' }}/>} placeholder="введите логин" size="large" />
                </Form.Item>
                <Form.Item style={{ marginBottom: 7 }} name="password" rules={[{ required: true, message: 'Введите пароль' }]}>
                    <Input prefix={<LockOutlined style={{ color: 'rgba(0,0,0,.25)' }}/>} type="password" placeholder="введите пароль" size="large" />
                </Form.Item>
                <Form.Item style={{ marginBottom: 7 }} onClick={resetModal}>
                <li>
                        <a>Политика СВФУ в отношении обработки персональных данных</a>
                </li>
                </Form.Item>
                <Form.Item style={{ marginTop: 10 }}>
                        <Checkbox onChange={onCheck}>Согласен с политикой обработки персональных данных</Checkbox>
                    </Form.Item>
                <Form.Item style={{ marginBottom: 0 }}>
                    <Button type="primary" htmlType="submit" block size="large" disabled={buttonDis}>
                        Вход
                    </Button>
                </Form.Item>
        </Form>
        </Spin>
    );
};

export default connect(
    null,
    { setUser }
)(LoginForm);
